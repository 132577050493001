import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MenuButton',
    props: {
        navigationText: {
            type: String,
            default: 'Navigation'
        },
    },
    setup(__props) {
        const handleClick = () => {
            const offCanvasWrapElement = document.getElementsByClassName('off-canvas-wrap')[0].classList;
            offCanvasWrapElement.contains('move-right')
                ? offCanvasWrapElement.remove('move-right')
                : offCanvasWrapElement.add('move-right');
        };
        const hideButtonOnCheckoutPages = () => {
            const regex = /training\/[^/]+\/registration/;
            return window.location.href.includes('training/register') || regex.test(window.location.href);
        };
        onMounted(() => {
            document.addEventListener(('exit-canvas'), () => {
                handleClick();
            });
        });
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("a", {
                class: _normalizeClass(["menu-button icon-hamburger-svg", { hidden: hideButtonOnCheckoutPages() }]),
                href: "#",
                onClick: _withModifiers(handleClick, ["prevent"])
            }, _toDisplayString(props.navigationText), 3));
        };
    }
});
