import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "row ocn-profile"
};
const _hoisted_2 = { class: "left ocn-profile-name" };
const _hoisted_3 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = ["href"];
const _hoisted_7 = ["href"];
const _hoisted_8 = ["href"];
const _hoisted_9 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_10 = {
    href: "https://www.zumbini.com/",
    class: "ocn-list-link",
    rel: "noopener",
    target: "_blank"
};
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = {
    href: "",
    class: "more-below ocn-list-link"
};
const _hoisted_13 = { class: "ocn-list-lang" };
const _hoisted_14 = { class: "ocn-list-item" };
const _hoisted_15 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_16 = { class: "ocn-list-item" };
const _hoisted_17 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_18 = { class: "ocn-list-item" };
const _hoisted_19 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_20 = { class: "ocn-list-item" };
const _hoisted_21 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_22 = { class: "ocn-list-item" };
const _hoisted_23 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_24 = { class: "ocn-list-item" };
const _hoisted_25 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_26 = { class: "ocn-list-item" };
const _hoisted_27 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_28 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_29 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_30 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_31 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_32 = { class: "ocn-list-item" };
const _hoisted_33 = ["href"];
const _hoisted_34 = ["srcset"];
const _hoisted_35 = ["src", "alt"];
const _hoisted_36 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_37 = ["href"];
import { onMounted, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Menu',
    props: {
        t: {
            type: Object,
            default: () => ({
                helloText: 'Hello',
                myAccountText: 'My Account',
                signInText: 'Sign In',
                zumbiniText: 'Zumbini',
                languageText: 'Language',
                englishText: 'English',
                frenchText: 'French',
                italianText: 'Italian',
                spanishText: 'Spanish',
                portugueseText: 'Portuguese',
                deutschText: 'Deutsch',
                koreanText: 'Korean',
                indonesianText: 'Indonesian',
                countryText: 'Country',
                signOutText: 'Sign Out',
                logInText: 'Log In',
                signUpText: 'Sign Up',
                joinZinText: 'Join ZIN™',
                updatePaymentText: 'Update Payment',
            })
        },
        active: { type: String, default: '' },
        countryCode: { type: String, default: 'us' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        iconBase64Encode: { type: String, default: '' },
        isIndonesianLanguageIncluded: { type: Boolean, default: false },
        isZumbiniLogoEnabled: { type: Boolean, default: false },
        language: { type: String, default: 'EN' },
        localeRedirect: { type: String, default: '' },
        loginUrl: { type: String, default: '' },
        signUpUrl: { type: String, default: '' },
        profileName: { type: String, default: '' },
        uri: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        isAclHeaderEnabled: { type: Boolean, default: false },
        isJapaneseEnabled: { type: Boolean, default: false },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        const accordionRef = ref(null);
        const $ = window.$;
        var setActiveLink = function (e) {
            const el = $(e.currentTarget);
            const children = el.siblings('ul');
            const hasChildren = children.length;
            const isTopLevelLink = el.parent().parent().hasClass('ocn-list');
            const isActive = el.hasClass('js-active-item');
            // If the link has child elements, don't navigate to them
            if (hasChildren) {
                e.stopPropagation();
                e.preventDefault();
            }
            // If the link has child elements and isn't active, open the menu
            if (hasChildren && !isActive) {
                openMenu(children);
            }
            // If the link has child elements and is active, close menu and all submenus
            if (hasChildren && isActive) {
                closeMenu(children);
                openMenu(children.find('li:hidden'));
                closeMenu(children.find('ul'));
            }
            // If the link isn't top-level, hide other choices in previous level
            if (!isTopLevelLink) {
                hideInactiveMenus(children);
            }
            // Set the active or inactive state on link
            setLinkStatus();
        };
        const setActivePageState = function (link) {
            const el = $(link);
            if (el.hasClass('pre-selected-active')) {
                const children = el.siblings('ul');
                const hasChildren = children.length;
                const isTopLevelLink = el.parent().parent().hasClass('ocn-list');
                const isActive = el.hasClass('js-active-item');
                // If the link has child elements and isn't active, open the menu
                if (hasChildren && !isActive) {
                    openMenu(children);
                }
                // If the link isn't top-level, hide other choices in previous level
                if (!isTopLevelLink) {
                    hideInactiveMenus(children);
                }
            }
        };
        var openMenu = function (menu) {
            menu.siblings('a').addClass('js-active-item');
            menu.slideDown().addClass('js-active-menu');
        };
        var hideInactiveMenus = function (menu) {
            menu.parent().siblings('li').slideToggle();
        };
        var closeMenu = function (menu) {
            menu.siblings('a').removeClass('js-active-item');
            menu.slideUp().removeClass('js-active-menu');
        };
        var setLinkStatus = function () {
            $('.ocn-list a').removeClass('active-item').removeClass('inactive-item');
            $('.js-active-item:last').addClass('active-item');
            $('.js-active-item').not(':last').addClass('inactive-item');
        };
        onMounted(() => {
            if (!accordionRef.value) {
                return;
            }
            const links = accordionRef.value.querySelectorAll('a');
            links.forEach(link => {
                link.addEventListener('click', event => setActiveLink(event));
                setActivePageState(link);
            });
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                (props.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(`${props.t.helloText} ${props.profileName}`), 1),
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/account',
                            class: "right ocn-settings",
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Account Gear",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Account Gear",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(props.t.myAccountText), 9, _hoisted_3)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (props.isAclHeaderEnabled)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("a", {
                                    href: props.loginUrl,
                                    class: "ocn-user-acl"
                                }, _toDisplayString(props.t.logInText), 9, _hoisted_6),
                                _createElementVNode("a", {
                                    href: props.signUpUrl,
                                    class: "ocn-user-acl"
                                }, _toDisplayString(props.t.signUpText), 9, _hoisted_7)
                            ]))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: props.loginUrl,
                                class: "ocn-user-session"
                            }, _toDisplayString(props.t.signInText), 9, _hoisted_8))
                    ])),
                _createElementVNode("ul", {
                    ref_key: "accordionRef",
                    ref: accordionRef,
                    class: "accordion no-bullet ocn-list"
                }, [
                    _renderSlot(_ctx.$slots, "usermenu"),
                    _renderSlot(_ctx.$slots, "support"),
                    (props.canAccessSixWeeksProgramProfile)
                        ? _renderSlot(_ctx.$slots, "6week", { key: 0 })
                        : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "strong"),
                    (props.isZumbiniLogoEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                            _createElementVNode("a", _hoisted_10, _toDisplayString(props.t.zumbiniText), 1)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("li", _hoisted_11, [
                        _createElementVNode("a", _hoisted_12, [
                            _createTextVNode(_toDisplayString(props.t.languageText) + " ", 1),
                            _createElementVNode("span", _hoisted_13, _toDisplayString(props.language), 1)
                        ]),
                        _createElementVNode("ul", {
                            class: "ocn-subnav",
                            style: _normalizeStyle(props.active ? 'display: block;' : '')
                        }, [
                            _createElementVNode("li", _hoisted_14, [
                                _createElementVNode("a", {
                                    href: '/en-US' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "English",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "English",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.englishText), 9, _hoisted_15)
                            ]),
                            _createElementVNode("li", _hoisted_16, [
                                _createElementVNode("a", {
                                    href: '/fr-FR' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "French",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "French",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.frenchText), 9, _hoisted_17)
                            ]),
                            _createElementVNode("li", _hoisted_18, [
                                _createElementVNode("a", {
                                    href: '/it-IT' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "Italian",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "Italian",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.italianText), 9, _hoisted_19)
                            ]),
                            _createElementVNode("li", _hoisted_20, [
                                _createElementVNode("a", {
                                    href: '/es-ES' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "Spanish",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "Spanish",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.spanishText), 9, _hoisted_21)
                            ]),
                            _createElementVNode("li", _hoisted_22, [
                                _createElementVNode("a", {
                                    href: '/pt-BR' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "Portuguese",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "Portuguese",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.portugueseText), 9, _hoisted_23)
                            ]),
                            _createElementVNode("li", _hoisted_24, [
                                _createElementVNode("a", {
                                    href: '/de-DE' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "Deutsch",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "Deutsch",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.deutschText), 9, _hoisted_25)
                            ]),
                            _createElementVNode("li", _hoisted_26, [
                                _createElementVNode("a", {
                                    href: '/ko-KR' + props.uri,
                                    class: "ocn-list-link",
                                    "data-ga-category": "Global Language Switch",
                                    "data-ga-action": "Korean",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "global_language_switch",
                                    "data-ga4-action": "Korean",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(props.t.koreanText), 9, _hoisted_27)
                            ]),
                            (props.isIndonesianLanguageIncluded)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_28, [
                                    _createElementVNode("a", {
                                        href: '/id-ID' + props.uri,
                                        class: "ocn-list-link",
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Indonesian",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_language_switch",
                                        "data-ga4-action": "Indonesian",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.indonesianText), 9, _hoisted_29)
                                ]))
                                : _createCommentVNode("", true),
                            (props.isJapaneseEnabled)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_30, [
                                    _createElementVNode("a", {
                                        href: '/ja-JP' + props.uri,
                                        class: "ocn-list-link",
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Japanese",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_language_switch",
                                        "data-ga4-action": "Japanese",
                                        "data-ga4-details": props.gaLabel
                                    }, " 日本語 ", 8, _hoisted_31)
                                ]))
                                : _createCommentVNode("", true)
                        ], 4)
                    ]),
                    _createElementVNode("li", _hoisted_32, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/localization?redirect=' + encodeURIComponent(props.localeRedirect),
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item': props.active }]),
                            title: "Zumba"
                        }, [
                            _createTextVNode(_toDisplayString(props.t.countryText) + " ", 1),
                            _createElementVNode("picture", null, [
                                _createElementVNode("source", {
                                    srcset: 'data:image/webp;base64,' + props.iconBase64Encode,
                                    type: "image/webp"
                                }, null, 8, _hoisted_34),
                                _createElementVNode("img", {
                                    class: "flag-only",
                                    src: '/img/blt/icons/country-flags/' + props.countryCode + '.png',
                                    alt: props.countryCode.toUpperCase()
                                }, null, 8, _hoisted_35)
                            ])
                        ], 10, _hoisted_33)
                    ]),
                    (props.isAuthenticated)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_36, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/logout',
                                class: "ocn-list-link"
                            }, _toDisplayString(props.t.signOutText), 9, _hoisted_37)
                        ]))
                        : _createCommentVNode("", true)
                ], 512)
            ]));
        };
    }
});
