import { currentUserLocale } from "@ts/Util/i18n";
const twelveHourCountries = [
    'US',
    'CA',
    'NZ',
    'AU',
    'PR',
    'GU',
    'VI'
];
const defaultOptions = {
    month: 'long',
    day: 'numeric'
};
export const todayISO = () => {
    var d = new Date();
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = d.getDate().toString();
    return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
};
export const is12HourTime = () => twelveHourCountries.indexOf(window.zumba_localization.country) >= 0;
/**
 * @param date Date Object
 * @returns Ordinal representation just in English
 */
export const getOrdinalFromDate = (date) => {
    let ordinal = '';
    const locale = Intl.DateTimeFormat().resolvedOptions().locale || 'en';
    if (String(locale).startsWith('en')) {
        switch (date.getDate() % 10) {
            case 1:
                ordinal = 'st';
                break;
            case 2:
                ordinal = 'nd';
                break;
            case 3:
                ordinal = 'rd';
                break;
            default:
                ordinal = 'th';
                break;
        }
        if (date.getDate() === 11 ||
            date.getDate() === 12 ||
            date.getDate() === 13) {
            ordinal = 'th';
        }
    }
    return ordinal;
};
export const roundToDay = (date) => {
    date = new Date(date.toJSON());
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};
export const stringToLocalizedDate = (value, options = defaultOptions) => {
    const date = new Date(value);
    const locale = currentUserLocale() ?? 'en-US';
    return date.toLocaleDateString(locale, options);
};
/**
 * Take an absolute number of minutes (i.e. 540 => 9:00 AM) and apply it to a Date
 *
 * @param  {Date}   date       An instance of Date
 * @param  {Number} numMinutes An absolute number of minutes
 * @return {Date}              The same Date instance (not cloned)
 */
export const applyMinutes = (date, numMinutes) => {
    date = new Date(date.toJSON());
    var minutes = numMinutes % 60, hours = (numMinutes - minutes) / 60;
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
};
export const stringToLocalizedTime = (value, options = defaultOptions) => {
    const date = new Date(value);
    const locale = currentUserLocale() ?? 'en-US';
    return date.toLocaleTimeString(locale, options);
};
