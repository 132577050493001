import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = {
    href: "#",
    class: "ocn-list-link"
};
const _hoisted_3 = { class: "ocn-subnav" };
const _hoisted_4 = { class: "ocn-list-item" };
const _hoisted_5 = {
    href: "#",
    class: "ocn-list-link"
};
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["href"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'OnHold',
    props: {
        currentUserLocale: { type: String, default: '' },
        t: {
            type: Object,
            default: () => ({
                onHoldEndStatusText: '',
                onHoldEndUntilText: '',
                accountOnHoldText: 'Account on Hold',
                updateOnHoldDateText: 'Update on Hold Date',
            })
        },
        isOnHold: { type: String, default: '' },
        onHoldEnd: { type: String, default: '' }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isOnHold)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    _createElementVNode("a", _hoisted_2, _toDisplayString(__props.t.accountOnHoldText), 1),
                    _createElementVNode("ul", _hoisted_3, [
                        _createElementVNode("li", _hoisted_4, [
                            _createElementVNode("a", _hoisted_5, [
                                _createTextVNode(_toDisplayString(__props.t.onHoldEndStatusText) + " ", 1),
                                (props.onHoldEnd)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(' ' + __props.t.onHoldEndUntilText), 1))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        _createElementVNode("li", _hoisted_7, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/zin/membership/hold_edit',
                                class: "ocn-list-link"
                            }, _toDisplayString(__props.t.updateOnHoldDateText), 9, _hoisted_8)
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
